import React from 'react'
import styles from './styles.module.css'
import NavBar from '../navbar'

const Container = ({ children }) => {
  return (
    <div className={styles.container}>
      <NavBar />
      {children}
    </div>
  )
}

export default Container
