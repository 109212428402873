import React from 'react'
import Header from '../components/Header'
import { Container } from '../components'
import styles from './styles.module.css'
import david from './me.jpg'

export default () => {
  return (
    <Container>
      <div className={styles.contentContainer}>
        <div className={styles.description}>
          <Header />
          <p>
            Frontend developer with a passion for create clean and reusable code
            with Cutting edge technology.
          </p>
          <a
            className={styles.contactButton}
            href='https://davidvillanueva120713.typeform.com/to/AvZ6F8'
            target='blank'
          >
            Contact Me
          </a>
        </div>
        <div className={styles.imageContainer}>
          <img src={david} alt='This is me' />
        </div>
      </div>
    </Container>
  )
}
