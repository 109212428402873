import React from 'react'
import styles from './styles.module.css'
import logo from './logo.png'
import Linkedin from './linkedin.svg'
import Github from './github.svg'

const NavBar = () => {
  return (
    <nav>
      <div className={styles.navbarLeft}>
        <img src={logo} alt='david logo' width='60' height='60' />
      </div>
      <div className={styles.navbarRight}>
        <ul className={styles.linkList}>
          <li>
            <a
              className={styles.headerLink}
              href='https://www.linkedin.com/in/david-villanueva-ar%C3%A9valo-77a11618/'
              target='blank'
            >
              <Linkedin />
            </a>
          </li>
          <li>
            <a
              className={styles.headerLink}
              href='https://github.com/Newville23'
              target='blank'
            >
              <Github />
            </a>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default NavBar
